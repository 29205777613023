<template>
  <div id="app">
    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword">
      <template #tools>
        <CButton color="info" size="sm" class="m-1" @click="download" :disabled="!selected">ダウンロード</CButton>
      </template>

    </MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false"
        @on-selection-change="selectionChanged">
      <!-- HEADER ソート可能にするために 必要-->
      <template slot-scope="{ column,index }" slot="dateHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
<!--      <template slot-scope="{ column,index }" slot="serialNumberHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
<!--      <template slot-scope="{ column,index }" slot="specFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
<!--      <template slot-scope="{ column,index }" slot="gwNowFirmwareIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
<!--      <template slot-scope="{ column,index }" slot="firmwareVersionHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
<!--      <template slot-scope="{ column,index }" slot="memoHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>-->
      <!-- cell -->

      <template slot-scope="{ row,index }" slot="date">
        <div class="text-left">{{row.date}}</div>
      </template>

    </BeeGridTable>

  </div>
</template>

<script>
import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";

export default {
  name: "DeviceSummaryDays",
  mixins: [BeeMix],
  components: {
    MyBeeHeader, MyBeeSearchBar
  },
  props:{
    yearMonth: { default: '2020/12', type: String },
    isShow: { default: false, type: Boolean },
  },
  data() {
    return {
      // searchBarPlaceHolder:"日付(yyyy/MM/dd)で検索",
      columns: [
        {type: "selection", width: 60, maxWidth: 50,   align: "center", },
        // {title: 'GWシリアル番号', key: 'deviceId',headSlot: 'serialNumberHead'},
        {title: '年月日', key: 'date',slot:"date",headSlot:'dateHead'},
        {title: 'ファイル数', key: 'fileCount'},
      ],
      selected:false,
      searchBarPlaceHolder:"年/月/日で検索",
      pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "date", sort: "desc"}]}),
    };
  },methods:{
    selectionChanged:function(){
      this.getCheckedList();
      this.selected = this.checkedList.length > 0;
    },
    download:function(){
      let checkedDateList = this.checkedList.map(function(item){
        return item.date;
      });

      this.isLoading = true;
      this.axios.post('/api/oxcsv/downlaod',
          {dates:checkedDateList}
          , {headers: {'Content-Type': 'application/json'},responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/ox_csv_year_month_paging', {pagingForm:this.pagingParams,yearMonth:this.yearMonth}, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
        this.selectionChanged();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },watch: {
    isShow:function(){
      if(this.isShow){
        this.checkedList = [];
        this.goodPaging();
      }else{
        this.pagingParams.keyword = null;
        this.rows = [];
      }
    },
    yearMonth(newValue, oldValue) {
      if (newValue == oldValue || this.yearMonth == "") {
        return;
      }
      this.goodPaging();
    }
  },
};
</script>