<template>
  <div>
    <CCard class="p-0 m-0 form-card">
      <CCardHeader>
        <label class="header-title">酸素濃縮装置運転状況</label>
      </CCardHeader>
      <CCardBody class="p-0">

        <CModal :show.sync="showDaysDownloadModal"
                size="xl"
                :centered="true"
                title="指定日ダウンロード">

          <DeviceSummaryDays :is-show="showDaysDownloadModal" :year-month="selectedMonth"/>
          <template #footer-wrapper><span></span></template>
        </CModal>
      </CCardBody>
    </CCard>
    <CCard class="p-0 m-0 grid-card">
      <CCardBody class="p-0">
        <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                        :curr-page.sync="pagingParams.currPage"
                        :per-page.sync="pagingParams.perPage"
                        :per-page-list="perPageList"
                        :pages="pages"
                        :search-bar-place-holder="searchBarPlaceHolder"
                        :keyword.sync="pagingParams.keyword">
          <template #tools>

          </template>
        </MyBeeSearchBar>
        <BeeGridTable
            ref="bee"
            border
            stripe
            :showFilter="false"
            :columns="columns"
            :loading="isLoading"
            :data="rows"
            :showPager="false"
            :maxHeight="gridCardHeight"
            highlightRow
            no-data-text="データがありません。"
            no-filtered-data-text=""
            :context="context"
            :span-method="customSpan"
            :showVerticalScrollBar="false"
            :showHorizontalScrollBar="false">
          <!-- HEADER ソート可能にするために 必要-->
          <template slot-scope="{ column,index }" slot="monthHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
          <template slot-scope="{ column,index }" slot="dayCountHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

          <!-- cell -->
          <template slot-scope="{ row,index }" slot="month"><div class="text-left">{{row.month}}</div></template>
          <template slot-scope="{ row,index }" slot="dayCount"><div class="text-left">{{row.dayCount}}</div></template>

          <template slot-scope="{ row,index }" slot="after">
            <div class="text-center">
              <CButton color="success py-0" size="sm" class="mr-1" @click="download(row)" :disabled="row.ym == null">ダウンロード</CButton>
              <CButton color="success py-0" size="sm" class="mr-1" @click="openDaysDownloadModal(row)">指定日ダウンロード</CButton>
            </div>
          </template>
        </BeeGridTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';


import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
import DeviceSummaryDays from "@/component/parts/DeviceSummaryDays";

export default {
  name: 'DeviceSummary',
  components:{
    MyBeeHeader, MyBeeSearchBar,DeviceSummaryDays
  },
  mixins: [BeeMix],
  data: () => {
    return {
      requestDate: '',
      columns: [
        {key: 'month', title: '年月',slot:'month',headSlot: "monthHead",align: 'center'},
        {key: 'dayCount',slot:"dayCount",title: '月内集計日数',align: 'center',headSlot: "dayCountHead"},
        {key: 'after',slot:'after',headSlot:'afterHead', title: ' '},
      ],
      pagingParams: Object.assign(BeeMix.data().pagingParams,{sort: [{field: "month", sort: "desc"}]}),
      selectedMonth:"",
      searchBarPlaceHolder:"年/月(YYYY/MM)で検索",
      showDaysDownloadModal:false
    }
  },
  methods: {
    download:function(row){

      this.isLoading = true;
      this.axios.post('/api/oxcsv/downlaod',
          {yearMonthList:[row.ym]}
          , {headers: {'Content-Type': 'application/json'},responseType: 'blob'}).then(
          response => {
            let url = window.URL.createObjectURL(new Blob([response.data]));
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', decodeURI(response.headers.filename));
            document.body.appendChild(link);
            link.click();
          }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    },
    openDaysDownloadModal:function(row){
      this.selectedMonth = row.month;
      this.showDaysDownloadModal = true;
    },
    goodPaging(isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/ox_csv_month_paging',
          this.pagingParams
          , {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => {
        this.isLoading = false;
      });
    }
  },
}
</script>
